import React from 'react';
import { Fade } from 'react-slideshow-image';
//import 'react-slideshow-image/dist/styles.css';
import image1 from '../../assets/image1.jpg';
import image2 from '../../assets/image2.jpg';
import image3 from '../../assets/image3.jpg';
import image4 from '../../assets/image4.jpg';
import image5 from '../../assets/image5.jpg';
import image6 from '../../assets/image6.jpg';

import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  ExternalLink,
} from '../../components';

import StaticPage from '../../containers/PageBuilder/StaticPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import css from './HomePage.module.css';
//import image from './path/to/image.png';

const HomePage = () => {

  const images = [
    {
      url: image1,
    },
    {
      url: image2,
    },
    {
      url: image3,
    },
    {
      url: image4,
    },
    {
      url: image5,
    },
    {
      url: image6,
    },
  ];

  const IMAGE_GALLERY_OPTIONS = {
    arrows: false,
    duration: 2000
  };

  return (
    <StaticPage
      className={css.root}
      title="Welcome"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HomePage',
        description: 'Split-Space homepage',
        name: 'Home',
      }}
    >
      <LayoutSingleColumn>

        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.wrapper}>
   
          <div className={css.container}>
            <h2 className={css.heading}>Wel-come!</h2>
            <div className="slide-container">
              <Fade
              {...IMAGE_GALLERY_OPTIONS}
              >
                {images.map((image, index) => (
                  <div className="each-fade" key={index}>
                    <img className={css.image} src={image.url} />
                  </div>
                ))}
              </Fade>
            </div>
              {/* <div className={css.left}>
                <NamedLink name="NewListingPage">
                  <button className={css.button}>I have an office<span className={css.span}>and I want to share it</span></button>
                </NamedLink>
              </div>
              <div className={css.right}>
                <NamedLink name="SearchPage" to={{ search: '?sort=+price' }}>
                  <button className={css.button}>I need an office<span className={css.span}>and I want to find someone who shares it</span></button>
                </NamedLink>
              </div> */}
            <div className={css.line}></div>
            <NamedLink name="NewListingPage" className={css.link}>
              <button className={css.button}>I have an office<span className={css.span}>and I want to share it</span></button>
            </NamedLink>
            <NamedLink name="SearchPage" to={{ search: '?sort=+price' }} className={css.link}>
              <button className={css.button}>I need an office<span className={css.span}>and I want to find someone who shares it</span></button>
            </NamedLink>
          </div>

        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>

      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default HomePage;